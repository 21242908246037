import { Query } from 'react-apollo';
import FlowboxContentQuery from '../Flowbox/FlowboxContentQuery.gql';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import ProductConfigurationContext from '@jetshop/core/components/ProductConfigurationProvider/ProductConfigurationContext';
import { useProductVariants } from '@jetshop/core/hooks/useProductVariants';
import t, { Intl } from '@jetshop/intl';
import { default as Breadcrumbs } from '@jetshop/ui/Breadcrumbs';
import getParentOrCanonicalRoute from '@jetshop/ui/Breadcrumbs/getParentsOrCanonicalRoute';
import { Above } from '@jetshop/ui/Breakpoints';
import { useStockStatus } from '@jetshop/ui/Stock/StockStatusContainer';
import { Form, useField } from 'formik';
import get from 'lodash.get';
import React, { useContext, useEffect, useMemo } from 'react';
import { styled } from 'linaria/react';
import { Helmet } from 'react-helmet-async';
import MaxWidth from '../Layout/MaxWidth';
import { Price } from '../Price';
import Button from '../ui/Button';
import InputWithLabel, { ErrorSpan, Label } from '../Forms/Input';
import AddToCartForm from './AddToCart/AddToCartForm';
import Campaigns from './Campaigns';
import ImageContainer from './Images/ImageContainer';
import ProductInfoAccordion from './ProductInfoAccordion';
import ProductPageLoadingState from './ProductPageLoadingState';
import { RelatedProducts } from './RelatedProducts';
import BaseSizePicker, { sortVariantOptionsArray } from './SizePicker';
import NotifyWhenBack from './StockStatus/NotifyWhenBack';
import { Container } from './styledComponents';
import Favorites from '../StartPage/Content/Favorites';
import ModalLink from './WarehouseStock';
import ProductColors from './ProductColors';
import BrandLogo from '../Brand/BrandLogo';
import QuantityInput from '../Forms/QuantityInput';
import { useQuery } from 'react-apollo';
import { BrandLinkQuery } from './BrandLinkQuery.gql';
import { Link } from 'react-router-dom';
import ProductColorQuery from './ProductColorsQuery.gql';
import Image from '@jetshop/ui/Image/Image';
import { AddFavourite } from '../ProductList/AddFavourite';
import { VoyadoTracking } from '../MyPages/VoyadoTracking';
import { usePriceDiffer } from './usePriceDiffer';
import { useVariantFromUrl } from '@jetshop/core/hooks/useProductVariants/useVariantFromUrl';
import { VariantSelector } from './SizePicker';
import Flowbox from '../Flowbox/FlowboxSimple';
import { PriceHistory } from './PriceHistory';
import { theme } from '../Theme';
import { cleanBreadcrumbs } from '../CategoryPage/CategoryHeader';
import {
  parsePosition,
  getPercentage,
  getTemplateName,
  getMatchingTemplates
} from '../CategoryPage/ProductCard';
import { useGlobalSettings } from '../Settings/GlobalSettings';
import { useCart } from '@jetshop/core/components/Query/CartProvider';
import cartQuery from '../Cart/CartQuery.gql';
import useSortSizes from './useSortSizes';
import { DynamicCategoryRenderer } from '../StartPage/ContentRendererComponents/DynamicCategoryRenderer/DynamicCategoryRenderer';
import { productPageComponents } from '../StartPage/ContentRendererComponents/allcomponents';
// import { StructuredProductData as StructuredData } from '@jetshop/core/components/StructuredData';
import dayjs from 'dayjs';
import useVisibilitySettings from '../../utils/useVisibilitySettings';


const ProductUpper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 4rem;
  ${theme.below.lg} {
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
`;
const ProductLeft = styled('div')`
  width: 65%;
  margin-right: 1rem;
  position: relative;
  & > div:first-of-type {
    ${theme.below.sm} {
      right: 10px !important;
      position: absolute;
      z-index: 2;
    }
  }
  & > h2 {
    ${theme.below.lg} {
      padding-top: 10px !important;
      margin-top: 10px !important;
    }
  }
  .favorite-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1;
    ${theme.below.sm} {
      right: 10px;
      top: 10px;
    }
  }
  ${theme.below.lg} {
    margin: auto;
  }
  ${theme.below.md} {
    width: 100%;
    padding: 0;
    margin-right: 10px;
    margin-left: 10px;
  }

  .image-gallery-image {
    & > div {
    }
  }
`;
const ProductRight = styled('div')`
  width: 35%;
  height: 100%;
  padding: 1rem;
  padding-right: 2rem;

  ${theme.below.lg} {
    width: 100%;
    padding: 1rem;
    padding-bottom: 0rem;
    height: auto;
  }
  ${theme.below.sm} {
    padding: 1rem 10px;
  }

  .stock-contact-info {
    font-size: 11px;
    width: 100%;
    ${theme.below.sm} {
      padding-left: 10px;
    }
    a {
      color: #000;
      font-weight: bold;
      text-decoration: none;
    }
  }
`;

const PickerWrapper = styled('div')`
  width: 100%;
  button {
    height: 48px !important;
  }
`;

const SizePickerWrapper = styled('div')`
  width: 100%;
  div {
    margin: 0;
  }
  .toggleSelect {
    border-color: black;
    h4 {
      font-family: ${theme.fonts.body};
      font-size: 14px;
      letter-spacing: 2px;
    }
  }
  span {
    letter-spacing: 1px;
    font-size: 12px;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  width: 100%;
  flex-direction: column;
  ${theme.below.md} {
    align-items: center;
  }
`;

const QuantityAddToCartWrapper = styled('div')`
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin-top: 0.3rem;
  label {
    display: none;
  }
  .quantity-input-wrapper input[type='number'] {
    border-radius: 0;
    height: 42px;
    background: #fff;
  }
`;

const ProductContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 1rem;
  ${theme.below.lg} {
    margin-bottom: 4rem;
  }

  .product-breadcrumbs {
    ${theme.below.lg} {
      padding: 1rem 0.5rem;
    }
    ${theme.below.sm} {
      padding: 0 10px;
      margin-bottom: 10px;
      margin-top: 10px;
    }
    li {
      color: #000;
      font-size: 11px;
      &:last-of-type {
        font-weight: bold;
      }
      a {
        color: #000;
        text-decoration: none;
        font-size: 11px;
      }
    }
  }

  ${theme.below.lg} {
    padding: 0;
    > div {
      padding: 0;
    }
  }

  header {
    display: block;
    align-items: flex-start;
    justify-content: space-between;
    font-weight: normal;
    position: relative;
    display: flex;
    .brand-logo {
      width: 60px !important;
      height: 60px !important;
      padding-bottom: 0 !important;
      position: absolute !important;
      right: 0;
      img {
        object-position: top !important;
      }
    }
    h1 {
      font-size: 16px;
      line-height: 22px;
      font-weight: normal;
      color: #000;
      ${theme.below.lg} {
        width: 260px;
      }
      + h2 {
        font-size: 12px;
        line-height: 22px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: normal;
      }
    }
    margin-bottom: 0;
  }
  .customer-comment-input-wrapper {
    .customer-comment-input {
      height: 44px;
    }
    .status-wrapper {
      top: -39px;
    }
  }
  .quantity-input-wrapper {
    margin-bottom: 0rem;
    margin-right: 0.7rem;
    width: 160px;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield; /* Firefox */
    }
    input {
      width: 44px;
      height: 54px;
      text-align: center;
      padding: 0px;
      margin-bottom: 0px;
    }
    label {
      text-align: center;
    }
  }
  .campaign-name {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0.5rem;
    margin-top: -2.2rem;
    color: ${theme.colors.accent};
    text-transform: uppercase;
    ${theme.below.md} {
      margin-top: 0;
    }
  }
  .symbol-description {
    font-size: 14px;
    line-height: 1.2;
    color: #000;
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid #e8e8e8;
    border-radius: 6px;
  }
  .short-description {
    line-height: 1.5;
    font-size: 14px;
    margin-bottom: 24px;
    font-family: 'Times New Roman', serif;
    letter-spacing: 0.25px;
    ${theme.below.lg} {
      margin-bottom: 0;
    }
    p {
      margin-bottom: 1rem;
    }
  }
  .delivery-list {
    li {
      display: flex;
      align-items: center;
      svg {
        margin-right: 16px;
      }
      margin-bottom: 16px;
    }
  }
  .product-details {
    ${theme.above.md} {
      align-items: flex-start;
    }
  }
`;

const PriceRange = styled('span')`
  font-size: 18px;
  margin-top: 8px;
  margin-right: 5px;
  font-weight: 600;
  line-height: 2;
`;

const PriceWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 25px;
  color: #000;
  &.price-range {
    .price,
    .old-price,
    .new-price {
      display: flex;
      align-items: flex-end;
      &::before {
        display: inline-block;
        content: ${props => props.fromText + ' '};
        font-size: 1.5rem;
        margin-right: 3px;
        font-family: ${theme.fonts.primary};
        padding-bottom: 5px;
      }
    }
    .old-price {
      &::before {
        padding-bottom: 0;
      }
    }
  }
  > div {
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
    margin-bottom: 27px;
    ${theme.below.md} {
      margin-bottom: 0.5rem;
    }
    div {
      margin-right: 10px;
    }
    .new-price {
      color: #b13827;
      margin-right: 8px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-family: 'RedRose';
      font-size: 2rem;
      line-height: unset;
      font-weight: 700;
    }
    .price {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-family: 'RedRose';
      font-size: 2rem;
      line-height: unset;
      margin-right: 0;
      font-weight: 900;
    }
    .old-price {
      color: ${theme.colors.grey};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-weight: normal;
      line-height: unset;
      padding-bottom: 6px;
    }
  }
  .percentage {
    background: #f7f7f7;
    color: #878787;
    border-radius: 1rem;
    padding: 2px 7px;
    font-size: 1rem;
    z-index: 3;
    font-weight: 700;
    margin-left: 0.5rem;
  }
`;

const BuyButton = styled(Button)`
display:flex;
flex-direction:column;
  background-color: ${theme.colors.accent};
  border: 2px solid ${theme.colors.accent};
  letter-spacing: 0.05em;
  display: flex;
  justify-content: center;
  text-align: center;
  text-decoration: none !important;
  text-transform: uppercase;
  width: 100%;
  font-weight: bold;
  font-size: 13px;
  padding: 0;
  color: white;
  height: 42px;
  line-height: 1.2;
  margin-bottom: 20px;
  &:hover {
    background-color: transparent;
    color: ${theme.colors.accent};
    border-color: ${theme.colors.accent};
  }
  &:focus {
    outline: none;
  }
  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: none;
    filter: grayscale(1);
  }
    span{
      font-weight:400;
      font-size:11px;
      text-transform:none;
    }
`;

const MinOrderValue = styled('div')`
  font-weight: bold;
  margin-top: 1rem;
  font-size: 12px;
  ${theme.below.md} {
    font-size: 14px;
  }
`;

const MaxReachedMessage = styled('p')`
position: absolute;
margin-top: 2.77rem;
font-size: 12px;
color: ${theme.colors.orange};
${theme.below.md} {
  font-size: 14px;
}
`;

const Names = styled('div')`
  max-width: calc(100% - 70px);
  display: flex;
  flex-direction: column;

  justify-content: space-evenly;
`;

const OtherColors = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  div {
    margin: 0.5rem;
    width: 75px;
    height: 75px;
  }
  ${theme.below.lg} {
    margin-bottom: 1rem;
  }
`;

const StoreDeliveryFlex = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  &.single {
    justify-content: flex-end;
  }
`;

const DeliveryInformation = styled('div')`
  font-size: 12px;
  margin-top: -1px;
  &.green {
    color: ${theme.colors.accent};
  }
  &.orange {
    color: ${theme.colors.orange};
  }
  ${theme.below.md} {
    font-size: 14px;
    width: 100%;
  }
`;

const BreadcrumbsMaxWidth = styled(MaxWidth)`
  flex-direction: row;
  justify-content: flex-start;
  ul {
    flex-direction: row;
  }
`;
const BreadcrumbArtNo = styled('span')`
  margin-left: 4px;
  font-size: 11px;
  color: black;
  display: block;
  ${theme.below.sm} {
    display: none;
  }
`;

const MobileArtNo = styled('span')`
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
  ${theme.above.sm} {
    display: none;
  }
`;

const FlowboxWrapper = styled('div')`
  padding: 20px 0;
  margin-top: 20px;
  width: 100%;
  .flowbox-content {
    max-width: 700px;
    margin: auto;
    margin-bottom: 20px;
    padding: 0 20px;
    text-align: center;
    a {
      color: black;
      text-decoration: underline;
    }

    + div {
      max-width: 100%;
      padding: 0;
    }
  }
  .flowbox-wrapper {
    max-width: 100%;
    padding: 0;
  }
`;

export const ProductSymbolWrapper = styled('div')`
  position: absolute;
  width: calc(${props => props.symbolSize});
  height: calc(${props => props.symbolHeight});
  z-index: 2;
  ${theme.below.sm} {
    width: calc(${props => props.symbolSizeMobile});
    height: calc(${props => props.symbolHeightMobile});
  }
  .product-symbol {
    padding-bottom: 0 !important;
    height: calc(${props => props.symbolHeight}) !important;
    ${theme.below.sm} {
      height: calc(${props => props.symbolHeightMobile}) !important;
    }
    display: content;
    picture {
      display: content;
    }
    img {
      padding: 0px !important;
      max-width: 100% !important;
      max-height: 100% !important;
      width: auto !important;
      height: auto !important;
      position: absolute !important;
    }
  }
`;

const DefaultPicker = ({ option, error, ...props }) => (
  <PickerWrapper>
    <Label>{option.name}</Label>
    {/* <SelectDropdown {...props} data-testid={`${option.name}-select-dropdown`} /> */}
    {error && <ErrorSpan>{error}</ErrorSpan>}
  </PickerWrapper>
);

function getBrand(product) {
  const field = product.customFields.find(({ key }) => key === 'ProductBrand');
  if (field) {
    return field.stringValue;
  }
}

function getWashing(product) {
  const field = product.customFields.find(
    ({ key }) => key === 'ProductProperties'
  );
  if (field) {
    return { title: field.title, value: field.htmlValue };
  }
}
function getBrandInfo(product) {
  const field = product.customFields.find(
    ({ key }) => key === 'TextresourceTypeInfoBrand'
  );
  if (field) {
    return { title: field.title, value: field.stringValue };
  }
}
function getWarningInfo(product) {
  const field = product.customFields.find(
    ({ key }) => key === 'TextresourceTypeWarning'
  );
  if (field) {
    return { title: field.title, value: field.htmlValue };
  }
}
function getImportantInfoHeadline(product) {
  const field = product.customFields.find(
    ({ key }) => key === 'TextresourceTypeImportantHeadline'
  );
  if (field) {
    return { title: field.title, value: field.htmlValue };
  }
}
function getImportantInfo(product) {
  const field = product.customFields.find(
    ({ key }) => key === 'TextresourceTypeImportant'
  );
  if (field) {
    return { title: field.title, value: field.htmlValue };
  }
}
function getYoutube(product) {
  const field = product.customFields.find(
    ({ key }) => key === 'TextresourceTypeYoutubeLink'
  );
  if (field) {
    return { id: 'youtube', value: field.htmlValue };
  }
}
function getMaterial(product) {
  const field = product.customFields.find(
    ({ key }) => key === 'ProductMaterial'
  );
  if (field) {
    return { title: field.title, value: field.stringValue };
  }
}
function getMaterialInfo(product) {
  const field = product.customFields.find(
    ({ key }) => key === 'TextresourceTypeInfoMaterial'
  );
  if (field) {
    return { title: field.title, value: field.htmlValue };
  }
}
function getGoodToKnow(product) {
  const field = product.customFields.find(
    ({ key }) => key === 'TextresourceTypeGoodToKnow'
  );
  if (field) {
    return { title: field.title, value: field.htmlValue };
  }
}

function getProductInfo(product) {
  const field = product.customFields.find(
    ({ key }) => key === 'ProductInformation'
  );
  if (field) {
    return { title: field.title, value: field.stringValue };
  }
}

const ProductMeta = ({ product, selectedVariation }) => {
  const { selectedChannel } = useContext(ChannelContext);

  const prod = selectedVariation || product;
  const brand = getBrand(product);

  return (
    <Helmet>
      <meta property="og:title" content={product.name} />
      <meta
        property="product:original_price:amount"
        content={prod?.price?.incVat}
      />
      <meta
        property="product:price:amount"
        content={prod?.previousPrice?.incVat}
      />
      <meta
        property="og:price:currency"
        content={selectedChannel.currency.id}
      />
      {product.images.length > 0 && (
        <meta property="og:image" content={product.images[0].url} />
      )}
      <meta
        property="product:availability"
        content={prod.stockStatus.buyable ? 'instock' : 'oos'}
      />
      {brand && <meta property="product:brand" content={brand} />}
      <meta
        property="product:category"
        content={product.canonicalCategory && product.canonicalCategory.name}
      />
      <meta
        property="og:url"
        content={`${selectedChannel.url}${product?.primaryRoute?.path}`}
      />
    </Helmet>
  );
};

const useOtherColors = artno => {
  const term = artno ? artno.split('-')[0] : null;
  const artNoLength = artno ? artno.split('-').length : 0;
  const { data, loading } = useQuery(ProductColorQuery, {
    variables: { term },
    skip: !term
  });
  return { colorData: data, colorLoading: loading, artNoLength };
};

function OtherColorsComponent({ product }) {
  const { colorData, colorLoading, artNoLength } = useOtherColors(
    product.articleNumber
  );
  if (colorLoading) return null;
  if (colorData.search.products.result.length > 1) {
    const products = colorData.search.products.result;
    const otherProducts = products.filter(
      colorProduct => colorProduct.articleNumber !== product.articleNumber
    );
    const otherColors = otherProducts.filter(
      colorProduct =>
        product.articleNumber.split('-')[2] ===
        colorProduct.articleNumber.split('-')[2]
    );
    return (
      <>
        {otherColors.length > 0 &&
          otherColors[0].articleNumber.split('-')[0] ==
            product.articleNumber.split('-')[0] && (
            <h2
              style={{
                textAlign: 'center',
                fontSize: '18px',
                marginTop: '2rem',
                paddingTop: '.5rem',
                borderTop: '1px solid #e8e8e8'
              }}
            >
              {t('More colors')}:
            </h2>
          )}

        <OtherColors>
          {products.map(
            (colorProduct, i) =>
              colorProduct.articleNumber !== product.articleNumber &&
              colorProduct.articleNumber.split('-')[0] ==
                product.articleNumber.split('-')[0] &&
              (artNoLength > 2 ? (
                product.articleNumber.split('-')[2] ==
                  colorProduct.articleNumber.split('-')[2] && (
                  <div key={i}>
                    <Link to={colorProduct?.primaryRoute?.path}>
                      <Image
                        src={colorProduct.images[0].url}
                        modifiedDate={colorProduct.images[0].modifiedDate}
                      />
                    </Link>
                  </div>
                )
              ) : (
                <div key={i}>
                  <Link to={colorProduct?.primaryRoute?.path}>
                    <Image
                      src={colorProduct.images[0].url}
                      modifiedDate={colorProduct.images[0].modifiedDate}
                    />
                  </Link>
                </div>
              ))
          )}
        </OtherColors>
      </>
    );
  } else {
    return null;
  }
}

const MaxQtyComment = ({ maxQty, setValues, alreadyInCart }) => {
  const [field, meta, helpers] = useField('comments');
  const maxReached = alreadyInCart >= maxQty;
  useEffect(() => {
    if (maxQty) {
      helpers.setValue({ max: maxQty.toString() });
    }
  }, [maxQty, setValues]);
  
  if (maxReached) {
    return (
      <MaxReachedMessage>
        {t('Max quantity reached in cart')}
      </MaxReachedMessage>
    );
  }
  return null;
};

export const isSaleProduct = product => {
  const hasKey = product?.customFields?.find(v => v.key === 'MarketingCategory')?.listValues?.includes('S4L3-PR0DUC75') ?? false;
  const loweredPrice = product?.price?.incVat < product?.previousPrice?.incVat;
  return hasKey && loweredPrice;
}

const CAMPAIGN_COLORS = {
  "rosa": theme.colors.pink,
  "grön": theme.colors.accent,
  "röd": theme.colors.christmasred,
  "svart": theme.colors.black
}

const Product = ({ result: { data, loading }, product }) => {
  const initialVariant = useVariantFromUrl();
  const { selectedChannel } = useContext(ChannelContext);
  const isSale = isSaleProduct(product)
  const { cart } = useCart(cartQuery);

  // GET PRODUCT TEMPLATE
  const { productTemplates,saleTemplate } = useGlobalSettings();
  const templates = getMatchingTemplates(product, productTemplates);
  const hasSaleTemplate = Object.keys(saleTemplate).length > 0;

  const productTemplate = productTemplates[templates?.[0]] ?? null;

   //SYMBOL FROM PRODUCT TEMPLATE VSISBILITY
   const countryVisibilitySettings = {
    sweden: productTemplate?.sweden,
    denmark: productTemplate?.denmark,
    norway: productTemplate?.norway,
    finland: productTemplate?.finland,
    netherlands: productTemplate?.netherlands,
    eu: productTemplate?.eu,
    loggedIn: productTemplate?.loggedIn,
    loggedOut: productTemplate?.loggedOut
  };
  const doNotRender = useVisibilitySettings(countryVisibilitySettings);

  useEffect(() => {
    const structuredDataScript = document.querySelector('script[type="application/ld+json"]');
    if (structuredDataScript) {
      // Parse the existing JSON data
      try {
        const jsonData = JSON.parse(structuredDataScript.textContent);
        // Add the "@id" field to the JSON object
        jsonData["@id"] = "#product";
        // Update the script's content with the modified JSON data
        structuredDataScript.textContent = JSON.stringify(jsonData, null, 2); // pretty-print the JSON for readability
      } catch (e) {
        console.error("Failed to parse JSON data", e);
      }
    } else {
      // Handle the case where the script tag does not exist
      console.error("No JSON-LD script tag found in the main element.");
    }
  }, [product]);

  let alreadyInCart = useMemo(() => {
    const prodVariants =
      product?.variants?.values?.map(variant => {
        return variant.articleNumber;
      }) ?? [];

    if (cart?.items) {
      const item = cart?.items?.filter(item => {
        if (prodVariants.includes(item.articleNumber)) {
          return true;
        }
        return item?.articleNumber === product?.articleNumber;
      });
      const total = item.reduce((acc, item) => {
        return acc + item.quantity;
      }, 0);
      return total;
    }
    return 0;
  }, [cart?.items]);

  const sortedSizesProduct = useSortSizes(product);
  const variantHandler = useProductVariants(sortedSizesProduct, {
    initialVariant
  });

  const { selectedVariant: selectedVariation } = variantHandler;

  const configurationContext = useContext(ProductConfigurationContext);
  const stockStatus = useStockStatus(selectedVariation || product);
  const hasPriceDiffer = usePriceDiffer({
    variants: product?.variants?.values
  });
  const images = get(product, 'images', []);
  const { colorData } = useOtherColors(product?.articleNumber);

  function useBrandLink(brand) {
    const { loading, error, data } = useQuery(BrandLinkQuery, {});
    if (loading) {
      return null;
    }
    if (error) {
      return `Error!: ${error}`;
    }
    if (data.category && data.category.subcategories) {
      const subCat = data.category.subcategories.filter(
        subCat => subCat.name===brand
      );
      if (subCat.length > 0) {
        return subCat[0]?.primaryRoute?.path;
      }
    }
    return null;
  }

  function getArtNoPartial(artNo) {
    const sections = artNo.split('-');
    const filteredSections = sections.filter(section => section !== '00000');
    return filteredSections.join('-');
  }


  const BrandLink = ({ brandLink }) => {
    const link = useBrandLink(brandLink);
    if (!link) return null;
    return (
      <Link to={link}>
        <BrandLogo brandName={getBrand(product)} />
      </Link>
    );
  };

  // Return early if there is no cache hit
  if (loading)
    return (
      <ProductContainer>
        <ProductPageLoadingState />
      </ProductContainer>
    );

  const parents = getParentOrCanonicalRoute(
    get(data, 'route.parents'),
    product
  );

  //SYMBOL FROM PRODUCT TEMPLATE SIZE
  const desktopScale = parseInt(productTemplate?.symbolDesktopSize) / 100 ?? 2;
  const mobileScale = parseInt(productTemplate?.symbolMobileSize) / 100 ?? 1.5;

  const desktopSize = (productTemplate?.symbolSize ?? 0) * desktopScale;
  const mobileSize = productTemplate?.symbolSize * mobileScale;

  const hasHeight = productTemplate?.symbolHeight !== null;
  const height = productTemplate?.symbolHeight * desktopScale;
  const mobileHeight = productTemplate?.symbolHeight * mobileScale;

  const campaignNameColor = CAMPAIGN_COLORS[productTemplate?.campaignNameColor?.toLowerCase()];
  //END SYMBOL FROM PRODUCT TEMPLATE SIZE
  const relatedProducts = get(product, 'relatedProducts');
  const recommendedProducts = get(product, 'recommendedProducts');
  const outOfStockNotify = stockStatus.status === 'notifyWhenBack';
  const favouriteMargin =
    productTemplate?.symbolPosition === 'Överkant - Höger';
    const saleBadges = [
      ...product.badges,
      {
        name: 'SaleSpecial',
        url: null,
        location: 'TOP_LEFT',
        style: saleTemplate.tagClass,
        text: saleTemplate.tagText
      }
    ];
    
  const maxProducts = parseInt(productTemplate?.maxProducts ?? 0) ?? 0;
  const isMax = maxProducts > 0 && alreadyInCart >= maxProducts;
  const calculatedMaxQuantity = () => {
    if (maxProducts - alreadyInCart <= 1) {
      return 1;
    } else {
      return maxProducts - alreadyInCart;
    }    
  }

  const artNo_partial = getArtNoPartial(product?.articleNumber);
 
  const fixedPercentage = getPercentage(selectedVariation || product);
  return (
    <>
    {/* { product && (
      <StructuredData
            product={product}
            extraFields={{
              "@id" : "#product",
            }}
          />
    )} */}
      <ProductContainer>
        <VoyadoTracking
          product={{ ...product, parents: parents }}
          variant={selectedVariation || null}
        />
        <AddToCartForm product={product} variant={selectedVariation}>
          {({ ...formProps }) => {
            return (
              <>
                <BreadcrumbsMaxWidth>
                  <Breadcrumbs
                    parents={parents}
                    breadcrumbText={cleanBreadcrumbs(product)?.breadcrumbText}
                    className="product-breadcrumbs"
                  />
                  <BreadcrumbArtNo>
                    – {t('Art. nr')} {product?.articleNumber}
                  </BreadcrumbArtNo>
                </BreadcrumbsMaxWidth>
                <Container>
                  <ProductMeta {...{ product, selectedVariation }} />

                  <ProductUpper>
                    <ProductLeft>
                      <Above breakpoint="md">
                        {matches =>
                          matches ? (
                            <AddFavourite
                              product={product}
                              style={{
                                float: 'right',
                                marginLeft: 'auto',
                                right: '.5rem',
                                fontSize: '1.5em',
                                marginTop: favouriteMargin
                                  ? `calc(0.5rem + ${desktopSize}px)`
                                  : '0.5rem',
                                zIndex: '2'
                              }}
                            />
                          ) : (
                            <AddFavourite
                              product={product}
                              style={{
                                float: 'right',
                                marginLeft: 'auto',
                                right: '.5rem',
                                fontSize: '1.5em',
                                marginTop: favouriteMargin
                                  ? `calc(0.5rem + ${mobileSize}px)`
                                  : '0.5rem',
                                zIndex: '2'
                              }}
                            />
                          )
                        }
                      </Above>

                      {product?.images && (
                        <ImageContainer
                          images={product.images}
                          badges={
                            isSale && hasSaleTemplate
                              ? saleBadges
                              : product.badges
                          }
                          youtube={getYoutube(product)}
                        >
                          {productTemplate && productTemplate?.symbol && !doNotRender && (
                            <ProductSymbolWrapper
                              symbolSize={desktopSize + 'px'}
                              symbolHeight={hasHeight ? height + 'px' : desktopSize + 'px'}
                              symbolSizeMobile={mobileSize + 'px'}
                              symbolHeightMobile={hasHeight ? mobileHeight + 'px' : mobileSize + 'px'}
                              style={parsePosition(
                                productTemplate?.symbolPosition
                              )}
                            >
                              <Image
                                className="product-symbol"
                                sizes={desktopSize}
                                aspect={'1:1'}
                                quality={100}
                                alt={productTemplate?.symbolText ?? ''}
                                title={productTemplate?.symbolText ?? ''}
                                src={productTemplate?.symbol}
                              />
                            </ProductSymbolWrapper>
                          )}
                        </ImageContainer>
                      )}

                      <Above breakpoint="md">
                        <OtherColorsComponent product={product} />
                      </Above>
                    </ProductLeft>
                    <ProductRight>
                      <div className="product-details">
                        <header>
                          <Names>
                            <h1 data-testid="product-title">
                              {product.name || 'Loading...'}
                            </h1>

                            <h2>{product.subName}</h2>
                            <MobileArtNo>
                              {t('Art. nr')}:{' '}
                              {(selectedVariation || product).articleNumber}
                            </MobileArtNo>
                          </Names>
                          <BrandLink brandLink={getBrand(product)} />
                        </header>{' '}
                        <a href="#allReviews">
                          <div
                            className="gmf-product-rating"
                            data-compact
                            data-product-id={artNo_partial}
                          ></div>
                        </a>
                        <Intl>
                          {t => (
                            <PriceWrapper
                              fromText={t('Fr.')}
                              className={
                                (!selectedVariation ? hasPriceDiffer : null)
                                  ? 'price-range'
                                  : 'single-price'
                              }
                            >
                              <Price
                                fromPrice={!selectedVariation && hasPriceDiffer}
                                price={(selectedVariation || product).price}
                                previousPrice={
                                  (selectedVariation || product).previousPrice
                                }
                                style={{
                                  marginBottom: '24px',
                                  opacity: (
                                    configurationContext
                                      ? configurationContext.loading
                                      : null
                                  )
                                    ? 0.3
                                    : 1
                                }}
                              >
                                {fixedPercentage > 0 && (
                                  <div className="percentage">
                                    -{fixedPercentage}%
                                  </div>
                                )}
                              </Price>
                            </PriceWrapper>
                          )}
                        </Intl>
                        <PriceHistory
                          product={product}
                          selectedVariation={selectedVariation}
                        />
                        { productTemplate?.symbolText && !doNotRender && (
                        <div 
                          className='campaign-name'
                          style={{color: campaignNameColor}}
                          dangerouslySetInnerHTML={{
                            __html: productTemplate?.campaignName
                          }}
                        />
                        )}
                        <div
                          className="short-description"
                          dangerouslySetInnerHTML={{
                            __html: product?.shortDescription
                          }}
                        />
                        <StyledForm>
                          <ProductColors
                            mainProductId={product?.id}
                            productColor={product?.subName}
                            productArtNo={product?.articleNumber}
                            productVariants={product}
                          />
                          {product.hasVariants && sortedSizesProduct && (
                            <VariantSelector
                              product={sortedSizesProduct}
                              variantHandler={variantHandler}
                            />
                          )}
                          {product.customerComments &&
                            product.customerComments.map(comment => (
                              <InputWithLabel
                                wrapperClassName="customer-comment-input-wrapper"
                                className="customer-comment-input"
                                data-testid={`${comment?.name}-input`}
                                name={`comments[${comment?.name}]`}
                                label={comment.name}
                                key={comment.name}
                              />
                            ))}

                          {!outOfStockNotify && (
                            <QuantityAddToCartWrapper>
                              <QuantityInput
                                disableValidation
                                wrapperClassName="quantity-input-wrapper"
                                min="1"
                                type="number"
                                name="quantity"
                                label="Quantity"
                                setValues={formProps?.setValues}
                                values={formProps?.values}
                                value={
                                  calculatedMaxQuantity() < maxProducts &&
                                  calculatedMaxQuantity()
                                }
                                disabled={true}
                                maximum={
                                  maxProducts > 0
                                    ? calculatedMaxQuantity()
                                    : 999
                                }
                              />
                              {maxProducts > 0 && (
                                <MaxQtyComment
                                  setValues={formProps?.setValues}
                                  maxQty={maxProducts}
                                  alreadyInCart={alreadyInCart}
                                />
                              )}
                              <BuyButton
                                data-testid="add-to-cart"
                                type="submit"
                                isMax={isMax}
                                className={
                                  (product ? product?.hasVariants : null)
                                    ? selectedVariation
                                      ? !selectedVariation.stockStatus?.buyable
                                        ? 'disabled'
                                        : null
                                      : 'disabled'
                                    : (
                                        product
                                          ? !product?.stockStatus?.buyable
                                          : null
                                      )
                                    ? 'disabled'
                                    : null
                                }
                              >
                                {product.stockStatus.text
                                  .toLowerCase()
                                  .includes('kommer snart') ? (
                                  <>
                                    {t('Comming soon')}
                                    {/* <span>
                                 
                                      {t('In stock')}{' '}
                                       {dayjs(
                                        product.stockStatus.stockDate
                                      ).format('MM/DD')}
                                      
                                    </span> */}
                                  </>
                                ) : product && product?.stockStatus?.buyable ? (
                                  <>{t('Add to cart')}</>
                                ) : (
                                  <>
                                    {product.stockStatus.text
                                      .replace(/\([^)]*\)/, '')
                                      .trim()}
                                  </>
                                )}
                              </BuyButton>
                            </QuantityAddToCartWrapper>
                          )}
                        </StyledForm>
                        {outOfStockNotify && (
                          <NotifyWhenBack
                            text={stockStatus.text}
                            articleNumber={
                              (selectedVariation || product).articleNumber
                            }
                          />
                        )}
                        <StoreDeliveryFlex
                          className={
                            (
                              product?.variants?.options?.length < 1
                                ? colorData?.search?.products?.result?.length ==
                                  1
                                : null
                            )
                              ? 'double'
                              : 'single'
                          }
                        >
                          {product?.variants?.options?.length < 1 &&
                            colorData?.search?.products?.result?.length ==
                              1 && (
                              <DeliveryInformation
                                className={
                                  product?.stockStatus?.buyable === true
                                    ? 'green'
                                    : 'orange'
                                }
                              >
                                {product?.stockStatus?.text
                                  .replace(/\([^)]*\)/, '')
                                  .trim()}
                              </DeliveryInformation>
                            )}
                          {!['EU', 'NL'].includes(selectedChannel.name) && (
                            <>
                              <ModalLink
                                initialIndex={selectedVariation?.articleNumber}
                                product={product}
                              >
                                {t('View store availability')}
                              </ModalLink>

                              {/* <span class="stock-contact-info">
                            {t(
                              "Contact your store to get current stock info. You'll find the stores contact info at "
                              )}
                              <a href="/stores">
                              {t('hooks.se/hitta-butik')}.
                              </a>
                              </span> */}
                            </>
                          )}
                        </StoreDeliveryFlex>
                        {/* <ProductPageUSP/>   */}
                        <DynamicCategoryRenderer
                          categoryId={3452}
                          rendererComponents={productPageComponents}
                        />
                        {productTemplate?.campaignDescription && !doNotRender && (
                        <div
                          className="symbol-description"
                          dangerouslySetInnerHTML={{
                            __html: productTemplate?.campaignDescription
                          }}
                        />
                        )}
                        <Campaigns
                          campaigns={product.campaigns}
                          isSale={isSale}
                          saleTemplate={saleTemplate}
                        />
                        <div
                          className="gmf-top-review"
                          data-product-id={artNo_partial}
                        ></div>
                        <ProductInfoAccordion
                          goodtoknow={getGoodToKnow(product)}
                          material={getMaterial(product)}
                          materialInfo={getMaterialInfo(product)}
                          washing={getWashing(product)}
                          brandInfo={getBrandInfo(product)}
                          importantinfoheadline={getImportantInfoHeadline(
                            product
                          )}
                          importantInfo={getImportantInfo(product)}
                          warningInfo={getWarningInfo(product)}
                          product={product}
                          artNo_partial={artNo_partial}
                          manufacturer={getProductInfo(product)}
                        />
                      </div>
                    </ProductRight>
                  </ProductUpper>
                  <Query variables={{ id: 2431 }} query={FlowboxContentQuery}>
                    {result => {
                      const { loading, data } = result;
                      if (loading || !data) return null;
                      return (
                        <FlowboxWrapper>
                          <div
                            className="flowbox-content"
                            dangerouslySetInnerHTML={{
                              __html: data?.category?.content
                            }}
                          />
                          <Flowbox
                            productId={product.articleNumber}
                            feed="_pLQjE6LQWq-SR4We_V-9A"
                          />
                        </FlowboxWrapper>
                      );
                    }}
                  </Query>
                </Container>
                {relatedProducts && (
                  <RelatedProducts
                    products={relatedProducts}
                    loading={loading}
                  />
                )}
                {recommendedProducts && (
                  <>
                    <RelatedProducts
                      products={recommendedProducts.viewed}
                      loading={loading}
                      title="You may also like"
                    />
                  </>
                )}
              </>
            );
          }}
        </AddToCartForm>
      </ProductContainer>
      <Favorites id={177} title={'Du kanske också gillar'} />
    </>
  );
};

export default Product;
