import React from 'react';
import { styled } from "linaria/react";
import { theme } from "../../Theme";

const CampaignBarLink = styled('a')`
  text-decoration: none;
`;

const StyledBar = styled('div')`
  background: ${theme.colors.accent};
  color: #fff;
  text-align: center;
  padding: 0.7rem 0;
  :hover {
    cursor: pointer;
  }

  p {
    font-size: 16px;
    font-weight: 700;
  }

  &.primary {
    background: ${theme.colors.accent};
    border: 1px solid ${theme.colors.accent};
  }
  &.blackweek {
    background: ${theme.colors.black};
    border: 1px solid ${theme.colors.black};
  }
  &.christmas-red {
    background: ${theme.colors.christmasred};
    border: 1px solid ${theme.colors.christmasred};
  }

  ${theme.below.md} {
    font-size: 16px;
    padding: 0.5rem 0;
    span {
      font-size: 18px;
      line-height: 27px;
      display: block;
      padding-bottom: 3px;
    }
    p:last-of-type {
      font-size: 16px;
    }
  }
`;

const textSizeClasses = {
  'small': '16px',
  'medium': '20px',
  'large': '24px'
}

const CampaignBar = ({ text, textSize, colorTheme, link}) => {
  const backgroundColor = colorTheme ? colorTheme?.value : 'primary';
  const campaignText = text?.value?.toUpperCase();
  const campaignLink = link?.value;
  const textSizeClass = textSize?.value ? textSizeClasses[textSize.value] : '16px';

  return (
    <>
    { campaignText && (
        <CampaignBarLink href={campaignLink}>
            <StyledBar className={backgroundColor}>
            <p style={{fontSize: textSizeClass}}>{campaignText}</p> 
            </StyledBar>
        </CampaignBarLink>
    )}
    </>
  );
};
export default CampaignBar;
